import { Link, useLocation } from "react-router-dom";
import Address from "./address";
import Wrapper from "./sectionWrapper";
import Messages from "./translations/Navbar.json";
import Msg from "./translations/Footer.json";
import { useLangContext } from "./translateContext";
import { media } from "../libs/media";
import { Icon } from "@iconify/react";
import siteMapMsg from "../page/sitemap/translations/index.json";
import { useEffect, useState } from "react";

const { Facebook, Instagram, LinkedIn, dss } = media.Social;

export default function Footer() {
  const { pathname } = useLocation();
  const [hide, setHide] = useState(false);
  const { Lang } = useLangContext();
  const Infos = Messages[Lang];
  const Mot = Msg[Lang];
  const siteMap = siteMapMsg[Lang];
  const Trad = {
    fr: "2023 Tous droits réservés",
    en: "2023 All rights reserved",
  };
  useEffect(() => {
    if (/\/docs/.test(pathname)) {
      window.scrollTo(0, 0);
      setHide(true);
    } else {
      setHide(false);
    }
  }, [pathname]);
  return (
    <footer
      className={`text-msm bg-qoswhite  md:text-dsm relative ${
        hide && "hidden"
      }`}
    >
      <div className="bg-qoswhite bg-opacity-40 absolute h-full w-full z-20"></div>
      <Wrapper
        childrenClassName="grid grid-flow-row content-center gap-y-6"
        className="py-6 border-b-2 border-qosgray border-opacity-50 text-qosdark relative z-30 text-opacity-70 mx-auto"
      >
        <nav className=" md:flex justify-between border-b border-qosgray border-opacity-50 pb-4 items-start font-medium md:font-normal md:mt-5">
          <div className="hidden md:flex justify-between">
            <Link to="/">
              {/* <div className="h-8 w-16 md:w-32 md:h-16 lg:w-24 lg:h-12 md:self-center">
                <img
                  src={media.Logos.qos.qosLogoBlack}
                  alt="QOS_logo"
                  className="h-full w-full"
                />
              </div> */}
              <div className="h-full w-16 md:w-20 lg:w-20">
                <img
                  src={media.Logos.qos.qosLogoBlack}
                  className="w-16 md:w-20 lg:w-20"
                  alt="QOS_LOGO"
                />
              </div>
            </Link>
          </div>
          <ul className="grid grid-flow-row gap-y-4 mb-6 md:mb-0">
            <p className="font-bold  text-xl  md:text-lg mb-2">
              {" "}
              {Infos.Links.products.name}{" "}
            </p>
            <li className="text-qosdark mb-1 md:text-lg">
              <Link to="/online-payment"> {Mot.Products1}</Link>
            </li>
            <li className="text-qosdark mb-1 md:text-lg">
              <Link to="/checkout"> {Mot.Products2}</Link>
            </li>
            <li className="text-qosdark mb-1 md:text-lg">
              <Link to="/transfer"> {Mot.Products3}</Link>
            </li>
            <li className="text-qosdark mb-1 md:text-lg">
              <Link to="/shareLink-payment">
                {" "}
                {Mot.Products4}
              </Link>
            </li>
            {/* <li className="text-qosdark mb-1 md:text-lg">
              <Link to="/store"> {Infos.Links.products.items[5].title}</Link>
            </li> */}
            <li className="text-qosdark mb-1 md:text-lg">
              <Link to="/offline-payment"> {Mot.Products6}</Link>
            </li>
            {/* <li className="text-qosdark mb-1">
              <Link to="/online-payment">
                {" "}
                {Infos.Links.products.items[0].title}
              </Link>
            </li> */}

            {/*             
            <li className="text-qosdark mb-1">
              <Link to="/offline-payment">
                {" "}
                {Infos.Links.products.items[1].title}
              </Link>
            </li> */}

            {/* <li className="text-qosdark mb-1">
              <Link to="/digital-finance">
                {" "}
                {Infos.Links.products.items[3].title}
              </Link>
            </li> */}
            {/* <li className="text-qosdark mb-1">
              <Link to="/digital-finance">
                {" "}
                {Infos.Links.products.items[3].title}
              </Link>
            </li> */}
          </ul>

          {/* <ul className="grid grid-flow-row gap-y-4 mb-6 md:mb-0">
            <p className="font-bold  text-xl  md:text-lg mb-2">
              {Mot.Solutions}
            </p>
            <li className="text-qosdark mb-1 md:text-lg">
              <Link to="#">{Mot.Solutions1}</Link>
            </li>
            <li className="text-qosdark mb-1 md:text-lg">
              <Link to="#">{Mot.Solutions2}</Link>
            </li>
            <li className="text-qosdark mb-1 md:text-lg">
              <Link to="#">{Mot.Solutions3}</Link>
            </li>
            <li className="text-qosdark mb-1">
              <Link to="#">{Infos.Links.company.items[1]}</Link>
            </li>
            <li className="text-qosdark mb-1">
              <Link to="/blog">{"Blog"}</Link>
              <a href="http://blog.qosic.com/" class="active">{"Blog"}</a>
            </li>
          </ul> */}

          <ul className="grid grid-flow-row gap-y-4 mb-6 md:mb-0">
            <p className="font-bold  text-xl  md:text-lg mb-2">
              {Infos.Links.developers}
            </p>
            <li className="text-qosdark mb-1 md:text-lg">
              {/* <Link href="https://docs.qosic.com/">Documentation</Link> */}
              <a href="https://docs.qosic.com/" target="_blank">Documentation</a>
            </li>
            <li className="text-qosdark mb-1 md:text-lg">
              {/* <Link to="/docs/checkoutstatus">{Mot.Developers2}</Link> */}
              <a href="https://docs.qosic.com/api-documentation/statut-de-la-transaction" target="_blank">
              {Mot.Developers2} </a>
            </li>
          </ul>

          <ul className="grid grid-flow-row gap-y-4 mb-6 md:mb-0">
            <p className="font-bold  text-xl  md:text-lg mb-2">
              {Mot.Resources}
            </p>
            <li className="text-qosdark md:text-lg">
              <Link to="/pricing"> {Mot.Resources1}</Link>
            </li>
            <li className="text-qosdark md:text-lg">
              <Link to="/support"> {Mot.Resources2}</Link>
            </li>
            <li className="text-qosdark md:text-lg">
              <Link to="/contact-us"> {Mot.Resources3}</Link>
            </li>
            <li className="text-qosdark md:text-lg">
              <a href="https://blog.qosic.com/" target="_blank">
              Blog
              </a>
              {/* <Link to="https://blog.qosic.com/" > </Link> */}
            </li>
            {/* <li className="text-qosdark md:text-lg">
              <Link to="#"> {Mot.Resources4}</Link>
            </li> */}
            {/* <li className="text-qosdark">
              <Link to="/sitemap"> {siteMap.links[3]}</Link>
            </li> */}
          </ul>

          <ul className="grid grid-flow-row gap-y-4 mb-6 md:mb-0">
            <p className="font-bold  text-xl  md:text-lg mb-2">
              <Link to="#"> {Mot.Company}</Link>
            </p>
            <li className="text-qosdark mb-1 md:text-lg">
              <Link to="#">{Mot.Company1}</Link>
            </li>
            <li className="text-qosdark mb-1 md:text-lg">
              <Link to="#">{Mot.Company2}</Link>
            </li>
            <li className="text-qosdark md:text-lg">
              <Link to="/privacy-policy">{siteMap.legal[2]}</Link>
            </li>
            <li className="text-qosdark md:text-lg">
              <Link to="/terms-and-conditions">{siteMap.legal[1]}</Link>
            </li>

            <li className="text-qosdark md:text-lg">
              <Link to="/cookies-policy">{siteMap.legal[3]}</Link>
            </li>
            <li className="text-qosdark md:text-lg">
              <Link to="/merchant-service-agreement">{siteMap.legal[4]}</Link>
            </li>
          </ul>
        </nav>
        <div
          className=" w-full "
          style={{ borderWidth: 0.5, borderColor: "#F8F8F9" }}
        ></div>

        <nav className="md:mt-5 md:flex  sm:flex justify-between">
          {/* <div className="flex justify-between mt-10 md:mt-0 max-w-xs w-64 mx-auto md:mx-0">
            <a href="https://www.facebook.com/qosintergratedcenter/">
              <img className="h-8 w-8 block" src={Facebook} alt="facebook" />
            </a>
            <a href="https://www.instagram.com/qosintegrated/">
              <img className="h-8 w-8 block" src={Instagram} alt="Instagram" />
            </a>
            <a href="https://www.linkedin.com/company/qos-intergrated-center">
              <img className="h-8 w-8 block" src={LinkedIn} alt="LinkedIn" />
            </a>
          </div>

          <Address
            className="max-w-xs w-64 md:w-10/12 mx-auto md:mx-0"
            textColor={`text-qosdark text-opacity-70`}
          /> */}
          {/* <div className="flex flex-col justify-center justify-items-center justify-center justify-self-center content-center items-center self-center place-content-center place-items-center place-self-center w-fit   lg:w-full ">
            <img
              className="block mx-auto  w-40 h-20   lg:w-60 lg:h-40 "
              src={dss}
              alt="dss"
            />
          </div> */}
        </nav>
        <div className=" grid grid-cols-1 md:flex w-full ">
          <div className=" w-full md:w-2/5">
            <div className="md:flex justify-start  border-qosgray border-opacity-50 pb-4 items-start font-medium md:font-normal md:mt-5">
              <div className=" w-full grid grid-flow-row gap-y-4 mb-6 md:mb-0">
                <p className="font-bold  text-xl  md:text-lg ">Contact</p>
                <p className="font-bold text-qosorange text-xl  md:text-dsm ">
                  hello@qosic.com
                </p>
                <div className="flex gap-2 justify-start items-center">
                  <a href="https://www.facebook.com/qosintergratedcenter/">
                    <Icon
                      icon="ic:baseline-facebook"
                      height={22}
                      style={{ color: "black", fontSize: "20px" }}
                    />
                  </a>
                  <a href="https://www.instagram.com/qosintegrated/">
                    <Icon
                      icon="mdi:instagram"
                      height={22}
                      style={{ color: "black", fontSize: "20px" }}
                    />
                  </a>
                  <a href="https://www.linkedin.com/company/qos-intergrated-center">
                    <Icon
                      icon="mingcute:linkedin-line"
                      height={22}
                      style={{ color: "black", fontSize: "20px" }}
                    />
                  </a>
                  <a href="#">
                    <Icon
                      icon="simple-icons:x"
                      height={20}
                      style={{ color: "black", fontSize: "20px" }}
                    />
                  </a>
                  <a href="https://github.com/QOS-Integated-Center">
                    <Icon
                      icon="mdi:github"
                      height={22}
                      style={{ color: "black", fontSize: "20px" }}
                    />
                  </a>
                  <a href="#">
                    <Icon
                      icon="ant-design:youtube-outlined"
                      height={25}
                      style={{ color: "black", fontSize: "20px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-3/5">
            <nav className="md:flex justify-around border-b border-qosgray border-opacity-50 pb-4 items-start font-medium md:font-normal md:mt-5">
              <ul className="grid grid-flow-row gap-y-4 mb-6 md:mb-0">
                <p className="font-bold   text-xl  md:text-lg  md:mb-2">
                  Benin
                </p>
                <li className="text-qosdark mb-1">
                  <p className="pr-2 md:text-lg">
                    Carre 557KPINGLA Lisette, Sikecodji, Cotonou
                  </p>
                </li>
              </ul>
              <ul className="grid grid-flow-row gap-y-4 mb-6 md:mb-0">
                <p className="font-bold   text-xl  md:text-lg  md:mb-2">Togo</p>
                <li className="text-qosdark mb-1 md:text-lg">
                  <p className="pr-14">
                    BE Ahligo, Rue KONDA, House AGOSSOU Amouzouvi, Lomév{" "}
                  </p>
                </li>
              </ul>
              <ul className="grid grid-flow-row gap-y-4 mb-6 md:mb-0">
                <p className="font-bold   text-xl  md:text-lg md:mb-2">
                  Nigeria
                </p>
                <li className="text-qosdark mb-1 md:text-lg">
                  <p className="pr-6">
                    4th floor, Polystar Building, Second Roundabout, Lekki,
                    Lagos
                  </p>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </Wrapper>
      <Wrapper className="bg-qoswhite mb-4  h-16 text-qosblack font-bold text-qosdark text-msm md:text-dsm text-left py-4">
        <p className="font-bold text-qosdark"> {Trad[Lang]} </p>
      </Wrapper>
    </footer>
  );
}
